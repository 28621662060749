import { MissionCode, MissionTaskStatus } from 'service/user';
import { addIgMissionBannerEvent } from 'common/utils/user';
import useStore from 'store/timeline';
import styles from './InvastBanner.module.scss';
import { useMemo } from 'react';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';

const randomBgUrl = () => {
  return '/images/banners/invast_banner.jpg';
};

const InvastBanner = ({
  fallbackElement,
  className,
}: {
  fallbackElement?: JSX.Element;
  className?: string;
}) => {
  const allMissions = useStore((state) => state.allMissions);
  const invastMission = useMemo(() => {
    return allMissions.find(
      (mission) => mission.code === MissionCode.InvastCreateAccount
    );
  }, [allMissions]);
  const showingBanner =
    invastMission &&
    [MissionTaskStatus.NotYet, MissionTaskStatus.InProgress].includes(
      invastMission?.current_task?.status
    );
  const bgUrl = useMemo(() => {
    return randomBgUrl();
  }, []);

  if (!showingBanner || !bgUrl)
    return fallbackElement ? fallbackElement : <></>;
  return (
    <div
      className={classNames(styles.banner, className ?? '')}
      onClick={() => {
        track('Click Top Banner', { banner_type: 'invast_banner' });
        addIgMissionBannerEvent('banner');
        window.open(invastMission?.link, '_blank')?.focus();
      }}
    >
      <img src={bgUrl} width="100%" />
    </div>
  );
};

export default InvastBanner;
