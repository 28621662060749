import styles from './IzanaviBanner.module.scss';
import { track } from '@amplitude/analytics-browser';
import classNames from 'classnames';
import { MINKABU_BANNER_PROMOTION_URL } from 'common/utils';
import {
  EventAction,
  EventCategory,
  EventComponent,
  ScreenName,
  addEvent,
} from 'common/utils/pp_tracking';
import { useEffect } from 'react';

const MinkabuBanner = ({ className }: { className?: string }) => {
  useEffect(() => {
    addEvent({
      action: EventAction.PageView,
      category: EventCategory.Campaign,
      screen: ScreenName.HomeTimeline,
      component: EventComponent.MinkabuBanner,
      timestamp: new Date().toISOString(),
      tracking_params: [
        {
          label: 'view',
          value: EventComponent.MinkabuBanner,
        },
      ],
    });
  }, []);
  return (
    <div
      className={classNames(styles.banner, className ?? '')}
      onClick={() => {
        track('Click Top Banner', { banner_type: 'minkabu_banner' });
        addEvent({
          action: EventAction.Click,
          category: EventCategory.Campaign,
          screen: ScreenName.HomeTimeline,
          component: EventComponent.MinkabuBanner,
          timestamp: new Date().toISOString(),
          tracking_params: [
            {
              label: 'click_position',
              value: EventComponent.MinkabuBanner,
            },
          ],
        });
        window.open(MINKABU_BANNER_PROMOTION_URL, '_blank');
      }}
    >
      <img src="/images/banners/minkabu_728x90.png" width="100%" />
    </div>
  );
};

export default MinkabuBanner;
