import { useMemo } from 'react';
import styles from './CoinBack202407Banner.module.scss';
import useStore from 'store/timeline';
import useGlobalStore from 'store/global';
import { track } from '@amplitude/analytics-browser';
import { getCoinBackMembershipEvent } from 'common/utils/user';
import classNames from 'classnames';
import { COIN_BACK_MEMBERSHIP_EVENT_CODE } from 'common/utils';
import { isMembershipAccount } from 'common/utils/membership';

const randomBgUrl = () => {
  const random = Math.floor(Math.random() * 2);
  if (random === 1) return '/images/banners/coin-back-banner-2024-07-01.jpg';
  return '/images/banners/coin-back-banner-2024-07.jpg';
};

const CoinBack202407Banner = ({ className }: { className?: string }) => {
  const currentUser = useStore((state) => state.currentUser);
  const coinBackEvent = getCoinBackMembershipEvent(currentUser);
  const bgUrl = useMemo(() => {
    return randomBgUrl();
  }, []);
  const showLogin = useGlobalStore((state) => state.showLogin);
  const setShowingCoinBackMembershipCampaignPopup = useGlobalStore(
    (state) => state.setShowingCoinBackMembershipCampaignPopup
  );
  if (!coinBackEvent) return <></>;
  if (isMembershipAccount(currentUser)) return <></>;
  return (
    <div
      className={classNames(
        styles.coinBackCampaignBannerWrapper,
        className ?? ''
      )}
      onClick={() => {
        if (!currentUser) {
          showLogin({});
          return;
        }
        track('Click Top Banner', {
          banner_type: COIN_BACK_MEMBERSHIP_EVENT_CODE,
        });
        setShowingCoinBackMembershipCampaignPopup(true);
      }}
    >
      <img src={bgUrl} width="100%" />
    </div>
  );
};

export default CoinBack202407Banner;
